.forgot-password-page-wrap {
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100vw;
  height: calc(100vh - 61px);
}

.forgot-password-title {
  font-size: 1.5em;
  background: rgba(255, 255, 255, 0.7);
  padding: 0.5em;
  margin-bottom: 1em;
}
