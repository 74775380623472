.event-exporer-row-wrap {
  margin: 0.5em;
  position: relative;
}
.event-exporer-row-wrap-inner {
  padding: 0.5em;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: white;
}
.event-exporer-row-wrap-image {
  height: 100%;
  width: 100%;
  position: absolute;
  opacity: 0.2;
  background-size: cover;
}
.event-explorer-item {
  display: inline-table;
  min-width: 15em;
  padding: 0.5em;
  margin: 0.2em;
  border: 1px dashed rgba(0, 0, 0, 0.2);
  background: white;
  position: relative;
}
.event-explorer-voice {
}
.event-explorer-botton-box {
  display: flex;
  flex-direction: row-reverse;
}

.event-explorer-header-line {
  display: flex;
  background: linear-gradient(90deg, #98b7db, white);
  justify-content: space-between;
  align-items: baseline;
  border-bottom: 1px solid #5482b9;
  border-right: 1px solid #5482b9;
}

.explore-event-main-box {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.1);
}

.explore-event-main-title {
  font-size: 1.4em;
  margin: 0 0.5em;
}

.event-display-icon {
  height: 5em;
  width: 5em;
  position: relative;
  z-index: 1;
  border: 1px solid;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
}

.event-display-item-type-wrap {
  text-align: center;
  padding: 0.5em;
  border-radius: 1em;
  flex-direction: column;
  display: flex;
  align-items: center;
  margin: 0.4em;
}
.event-display-item-type-wrap-outer {
  position: relative;
  display: inline-block;
  margin: 0.4em;
}

.end-explore-padder-ios-fixer {
  height: 5em;
}

.event-explorer-voice-type-label {
  font-weight: 600;
  color: white;
  padding: 0.2em 1em;
  position: relative;
  z-index: 1;
  background-color: #577daa;
  margin-top: 1em;
}

.event-explorer-voice-top-title {
  padding: 0.2em 1em;
  font-size: 1.1em;
  .ms-Label {
    color: rgb(7 14 22);
    font-weight: 600;
    font-style: italic;
    font-size: 1.05em;
  }
}

.event-display-item-type-scroll {
  padding: 0;
  position: absolute;
  object-fit: contain;
  height: 10em;
  width: 9em;
  margin-top: -2.5em;
}

.event-display-icon-overlay {
  position: absolute;
  width: 5em;
  height: 5em;
  z-index: 2;
  opacity: 0.1;
  border-radius: 50%;
}

.display-karma-row {
  display: flex;
  gap: 0.5em;
  align-items: center;
}

.display-karma-icon {
  img {
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.2);
    height: 5em;
  }
}

.display-karma-char-name {
  min-width: 10em;
}

.explore-event-toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5em;
  gap: 1em;
}

.explore-event-toolbar-buttons {
  display: flex;
  align-items: center;
  gap: 1em;
}
