.global-event-exporer-row-wrap {
  border: 1px solid #0003;
  border-bottom: 15px solid #0003;
  margin-bottom: 0.5em;
  padding: 0.5em;
}
.global-event-explorer-item {
  font-size: 0.8em;
}
.global-event-explorer-voice {
  opacity: 0.8;
}

.global-event-display-item-type-wrap {
  gap: 1em;
  display: flex;
  img {
    height: 2em;
  }
}
.global-event-display-icon {
}

.global-event-main-scroll {
  max-height: 40vh;
  overflow-y: auto;
}
