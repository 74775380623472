.smart-modal-outer-inkdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 100000;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: space-around;

  animation: fadeInAnimation ease 0.3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.smart-modal-wrap {
  border-radius: 0.2em;
  width: fit-content;
  margin: 1em;
  min-width: 15em;
  max-width: 800px;
  padding: 1em;
  background-color: white;
  box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.smart-modal-title-line {
  display: flex;
  align-items: center;
  gap: 1em;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.smart-modal-title {
  font-size: 1.2em;
  font-weight: 600;
}
.smart-modal-content {
  padding: 1em;
  padding-top: 0;
  max-height: 70vh;
  overflow: auto;
  padding-bottom: 0;
  background: rgb(246 250 255);
}
.smart-modal-row-buttons-wrap {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  width: 100%;
  padding-top: 1em;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.smart-modal-row-buttons {
  display: flex;
  gap: 1em;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
