.pad-main-wrap {
  padding: 1em;
  border-radius: 0.5em;
  box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
  position: relative;
  background: linear-gradient(45deg, #b9cee3, white);
  max-width: 500px;
  width: 80%;
  min-width: 15em;
  min-height: 10em;
}

.pad-main-wrap-outer {
  position: absolute;
  width: 100vw;
  height: calc(100vh - 55px);
  top: 55px;
  left: 0;
  background-color: rgba(255, 255, 255, 0.2);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
