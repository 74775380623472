.image-wrapper-v2 {
  position: relative;
  overflow: hidden;
  img {
    height: 100%;
    object-fit: cover;
  }
}

.image-wrapper-v1 {
  position: relative;
  overflow: hidden;

  img {
    height: 100%;
    object-fit: cover;
  }
}

.image-event-label {
  position: absolute;
  width: 100%;
  bottom: 0.5em;
  left: 0;
  padding: 0.5em;
  font-weight: 600;
  color: #fff;
  text-shadow: 1px 0 0 #00000073, 0 -1px 0 #00000073, 0 1px 0 #00000073,
    -1px 0 0 #00000073;
}
