.example {
}

.container {
  position: relative;
  top: 0;
  left: 0;
  height: 0.5em;
  width: 0.5em;
}

.block {
  position: relative;
  top: 0;
  left: 0;
  height: 0.5em;
  width: 0.5em;

  > .item {
    position: absolute;
    height: 0.5em;
    width: 0.5em;
    background: rgb(84 130 186);
    animation: move 2s linear infinite;

    &:nth-of-type(1) {
      top: -0.5em;
      left: -0.5em;
      animation-delay: -7 * 2s / 8;
    }

    &:nth-of-type(2) {
      top: -0.5em;
      left: 0;
      animation-delay: -6 * 2s / 8;
    }

    &:nth-of-type(3) {
      top: -0.5em;
      left: 0.5em;
      animation-delay: -5 * 2s / 8;
    }

    &:nth-of-type(4) {
      top: 0;
      left: 0.5em;
      animation-delay: -4 * 2s / 8;
    }

    &:nth-of-type(5) {
      top: 0.5em;
      left: 0.5em;
      animation-delay: -3 * 2s / 8;
    }

    &:nth-of-type(6) {
      top: 0.5em;
      left: 0;
      animation-delay: -2 * 2s / 8;
    }

    &:nth-of-type(7) {
      top: 0.5em;
      left: -0.5em;
      animation-delay: -1 * 2s / 8;
    }

    &:nth-of-type(8) {
      top: 0;
      left: -0.5em;
      animation-delay: 0 * 2s / 8;
    }
  }
}

@keyframes move {
  0% {
    transform: rotate(0) scale(1);
    animation-timing-function: ease-in;
  }
  10% {
    transform: rotate(90deg) scale(0);
  }
  50% {
    transform: rotate(90deg) scale(0);
    animation-timing-function: ease-out;
  }
  60% {
    transform: rotate(180deg) scale(1);
  }
  100% {
    transform: rotate(180deg) scale(1);
  }
}

.ai-super-spinner {
  box-shadow: 0 0 1em 0 rgb(137 229 214);
  width: fit-content;
  height: 4em;
  border-radius: 50%;
  overflow: hidden;
  background: radial-gradient(#0034ff 20%, #7dbec9 60%, #31a2b7 100%);
  padding: 0.3em;
  img {
    border-radius: 50%;
    height: 4em;
    opacity: 0.5;
  }
}

.raimbow-super-spinner {
  height: 10em;
  width: 10em;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  img {
    width: 10em;

    height: 10em;
  }
}

.raimbow-super-spinner-center-text {
  padding: 0;
  position: relative;
  top: -6.6em;
  left: 5.1em;
}

.center-object-spinner {
  padding: 1em;
}
