.multi-form-input-slot {
  display: inline-block;
  vertical-align: middle;
  margin-right: 1%;
  min-height: 3em;
  padding-bottom: 1em;
  position: relative;
  input {
    width: 100% !important;
  }
}

.required-label::after {
  content: " *";
  color: rgb(164, 38, 44);
  padding-right: 12px;
}

.custom-multi-form-label-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.multi-form-inline-form {
  display: flex;
  align-items: baseline;
  gap: 1em;
  .multi-form-input-slot {
    padding-bottom: 0;
  }
}

.multi-form-init-spinner {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 1em;
}

.multi-form-input-box {
  max-height: 55vh;
  overflow: auto;
  overflow-x: hidden;
}

@media screen and (max-width: 800px) {
  .multi-form-input-slot {
    width: 100% !important;
  }
}

.multi-form-tiny-submit-button {
  display: flex;
  flex-direction: row-reverse;
}
