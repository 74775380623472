.image-picker-wrap {
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-top: 1em;
  margin-bottom: 2em;
}

.image-profile-preview {
  object-fit: contain;
  margin: 0 auto;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
  border-radius: 0.5em;
}
