.my-purchase-page-wrap {
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100vw;
  height: calc(100vh - 60px);
  overflow-y: hidden;
}

.my-purchases-table-wrap {
  max-height: 80vh;
  overflow: auto;
}

.table-slot {
  min-width: 10em;
}
