.settings-page-main-wrap {
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100vw;
  height: calc(100vh - 60px);
  overflow-y: hidden;
}

.settings-page-buttons {
  display: flex;
  gap: 1em;
}

.settings-page-top-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
