.broken-bot-error-dect {
  img {
    height: 10em;
  }
  text-align: center;
}

.error-row-date {
  min-width: 10em;
  margin-right: 1em;
  display: inline-block;
}

.error-detection-answer-slot {
  padding: 1em 0;
}

.answer-slot-banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.answer-elapsed {
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.5);
  white-space: pre;
}

.table-bot-error-data {
  width: 100%;
  position: relative;
}

.table-bot-error-data-title {
  font-size: 1.2em;
  font-weight: 600;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  min-width: 90%;
}

.table-bot-error-data-mobile {
  .ms-MessageBar {
    .ms-MessageBar-text {
      font-size: 0.8em;
    }
  }
}

.table-bot-error-data-wrap {
}

.inline-error-line-message {
  max-width: 80vw;
}

.inline-error-line-message-bar {
  width: 100%;
  position: relative;
}

.inline-error-line-message-buttons {
  position: absolute;
  right: 0;
  top: 0.4em;
}

.inline-error-line-message-buttons-wrap {
  position: relative;
  width: 100%;
  z-index: 1;
}

.error-label-error {
  color: #804545;
  display: inline-block;
  font-weight: 600;
  font-size: 1.2em;
}
.error-label-warning {
  color: #808045;
  display: inline-block;
  font-weight: 600;
  font-size: 1.2em;
}
.error-label-info {
  color: #747474;
  display: inline-block;
  font-weight: 600;
  font-size: 1.2em;
}
