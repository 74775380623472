.book-box-outer {
  max-width: 500px;
  display: inline-block;
}

.book-box {
  display: flex;
  gap: 1em;
  margin: 0.5em;
  align-items: center;
  border: 1px solid #ccc;
  background-color: rgba(255, 255, 255, 0.651);
  padding: 0.5em;
  border-radius: 1em;
  cursor: pointer;
  overflow: hidden;
}
.book-box:hover {
  background-color: rgba(224, 230, 241, 0.8);
  box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
}
.book-image {
  width: 130px;
  border: 1px solid #0003;
  border-radius: 0.5em;
  box-shadow: 0 0 1em 0 #0003;
  object-fit: contain;
}
.book-info {
  font-size: 0.9em;
  text-align: left;
  padding: 0.5em;
}
.book-title {
  font-weight: 600;
}
.book-description {
  font-size: 0.8em;
  font-style: italic;
  max-height: 6em;
  overflow: auto;
}
