.karma-picker-input-row {
  display: flex;
  gap: 5px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 0.5em;
  padding-bottom: 0.5em;
}

.karma-picker-input {
  width: 30%;
}

.custom-karma-option {
  width: 100%;
}

.good-karma-icon {
  background: radial-gradient(#21fdff, #0090ff);
}
.neutral-karma-icon {
  background: radial-gradient(#8d8d8d, white);
}
.bad-karma-icon {
  background: radial-gradient(
    rgb(231 255 37 / 81%),
    rgb(255 30 30 / 81%) 70%,
    rgb(240 255 32)
  );
}
