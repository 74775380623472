.table-event-display-item-type-wrap {
  display: flex;
  gap: 0.5em;
  align-items: center;
  position: relative;
  img {
    height: 6em;
  }
  font-size: 0.8em;
}

.timeline-table-container {
  overflow-y: auto;
  max-height: 75vh;
}

.table-event-display-item-type-wrap-overlay {
  width: 6em;
  height: 6em;
  position: absolute;
  opacity: 0.2;
}

.table-event-display-item-type-wrap-outer {
  display: inline-block;
  margin: 1px;
}

.date-on-table {
  font-size: 1.2em;
  opacity: 0.8;
  margin-top: 0.2em;
  padding: 0.2em;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.table-event-char-line {
  padding: 0.5em;
  margin: 0.1em;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.05);
  border: 1px solid white;
}

.pretty-table-toolbar {
  display: flex;
  gap: 1em;
}
