.re-confirm-email-page-wrap {
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100vw;
  height: calc(100vh - 61px);
}

.re-confirm-email-inner-frame {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
