.loadable-main-wrap {
  min-width: 10em;
}

.loadable-spinner-wrap {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  min-height: 20vh;
}
