.tag-picker-box-wrap {
  position: absolute;
}

.input-mimic-fluent-box {
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI",
    -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-weight: 400;
  border: none;
  flex-grow: 1;
  outline: none;
  padding: 0px 6px;
  align-self: flex-end;
  border-radius: 2px;
  background-color: transparent;
  color: rgb(50, 49, 48);
  border: 1px solid #a3a2a0;
  position: relative;
}

.selection-picker-input {
}

.selection-picker-slot-wrap-outer {
  display: inline-block;
  margin: 0.2em;
}
.selection-picker-slot-wrap {
  display: flex;
  align-items: center;
  background: rgb(243, 242, 241);
  width: fit-content;
  gap: 0.5em;
}
.selection-picker-slot-name {
  padding: 0.2em 0.5em;
}

.selection-picker-slot-button {
  color: rgb(163, 162, 160);
}
.selection-picker-slot-button:hover {
  color: rgb(50, 49, 48);
  background: rgb(225, 223, 221);
}

.picker-invisible-div-content {
  display: inline-block;
  outline: none;
  max-width: 98%;
  padding: 0.4em;
  outline: none;
  border: none;
}

.picker-selection-menu {
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
  background: white;
  width: 15em;
  min-width: fit-content;
  animation: rollIn 0.2s ease-out;
  z-index: 1000;
}

.picker-selectin-menu-inner {
  max-height: 15em;
  overflow-y: auto;
}

.picker-option {
  padding: 0.8em 1em;
  color: rgb(90, 90, 89);
}
.picker-option:hover {
  background: rgb(225, 223, 221);
  cursor: pointer;
}

.no-results-picker-slot {
  text-align: center;
  padding: 1em;
}

.result-popup-title {
  padding: 0px 12px;
  font-size: 12px;
  color: rgb(84, 130, 186);
  line-height: 40px;
  border-bottom: 1px solid rgb(237, 235, 233);
}

@keyframes rollIn {
  0% {
    transform: translate3d(0px, -10px, 0px);
    pointer-events: none;
    opacity: 0;
  }
  100% {
    transform: translate3d(0px, 0px, 0px);
    pointer-events: auto;
    opacity: 1;
  }
}
