.workgroup-page-wrap {
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100vw;
  height: calc(100vh - 60px);
  overflow-y: hidden;
}

.button-add-on-right {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 2em;
}

.workgroup-selector {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1em;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3em;
  padding: 0.5em;
  margin-bottom: 1em;
  background: white;
}

.workgroup-selector-name {
  font-weight: 600;
  width: 100%;
}

.workgroup-selector-description {
  width: 100%;
}

.workgroup-picker-main-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.2em;
  font-weight: 600;
  padding-bottom: 0.5em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 1em;
}

.workgroup-selector-image {
  img {
    width: 8em;
    box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
    border-radius: 0.2em;
  }
}

.workgroup-selector-container {
  max-height: 40vh;
  overflow: auto;
}

.erease-workgroup-huge-warning {
  margin-bottom: 1em;
  width: 40em;
  max-width: 80vw;
  .ms-MessageBar-innerText {
    font-size: 1.5em;
  }
}

.workgroup-select-purchase-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  min-height: 2.4em;
}

.workgroup-select-purchase-item-left {
  display: flex;
  gap: 1em;
  min-width: 50%;
  margin-right: 1em;
}

.workgroup-select-purchase-item-right {
  color: rgba(0, 0, 0, 0.5);
}

.inline-button-selector-zone-labels {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
}
.inline-button-selector-zone {
  display: flex;
  align-items: center;
  gap: 1em;
  width: 100%;
}
.inline-button-selector-zone-wide {
  display: flex;
  align-items: center;
  gap: 1em;
  width: 100%;
  justify-content: space-between;
}

.inline-button-selector-zone-slot {
  display: flex;
  gap: 1em;
}
