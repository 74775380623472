.event-year-map {
  display: flex;
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 2em;
  font-size: 2em;
  padding-top: 3em;
  background-color: rgb(242, 242, 242);
}
.event-year-map-inner {
  display: flex;
  border-bottom: 2px solid #505050;
  box-shadow: 0 0 1em 20px rgb(133, 133, 133);
  background-color: #ffffffba;
}
.event-year-map-centerer {
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
}
.event-year-map-container-visibility-wrap {
  display: block;
}
.event-year-map-container {
  display: flex;
}

.event-year-tick {
  position: relative;
  width: 3px;
  height: 3em;
  background-color: black;
  margin-right: 0.8em;
}

.event-central-year-tick {
  position: relative;
  width: 1px;
  height: 3em;
  background-color: rgb(127 196 241);
  margin-right: 0.8em;
  border-right: 2px solid rgb(0, 153, 255);
  border-left: 2px solid rgb(0, 153, 255);
}
.event-display-dots-wrap-outer {
  position: relative;
}
.event-display-dots-context-positioner {
  position: absolute;
  margin-top: -1em;
  margin-left: -0.5em;
  width: 1em;
  height: 1em;
  display: flex;
  align-items: center;
  overflow: hidden;
  z-index: 1000;
  background: rgb(235, 235, 235);
  border-bottom-left-radius: 2em;
  border-bottom-right-radius: 2em;

  border: 1px solid #5482ba;
}
.event-month-tick {
  position: relative;
  width: 2px;
  height: 1.5em;
  background-color: black;
  margin-right: 0.8em;
}
.event-day-tick {
  position: relative;
  width: 1px;
  height: 0.8em;
  background-color: black;
  margin-right: 0.8em;
}

.event-year-tick-label {
  font-size: 0.8em;
  font-weight: 600;
}

.event-month-tick-label {
  font-size: 0.5em;
  background: white;
  padding: 0 0.2em;
  border-radius: 1em;
  border: 1px solid;
  width: fit-content;
  position: absolute;
  top: -3.2em;
  transform: rotate(-70deg);
  min-width: 4em;
  left: -0.7em;
}

.event-day-tick-label {
  font-size: 0.4em;
  position: relative;
  left: 0.3em;
}

.tick-event-main-wrap {
  cursor: pointer;
  border: 1px solid transparent;
  height: 5em;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.tick-event-main-wrap:hover {
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: rgba(0, 0, 0, 0.1);
}

.tick-event-main-wrap-active {
  border: 1px solid rgba(0, 0, 0, 0.4) !important;
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.center-year-label {
  position: relative;
  white-space: pre;
  font-weight: 600;
  bottom: -0.5em;
  text-decoration: underline;
  opacity: 0.7;
  color: rgb(255, 255, 255);
  text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
}

.center-year-label-month-view {
  position: relative;
  top: -4.5em;
  left: -1em;
  font-size: 0.8em;
  white-space: pre;
  font-weight: 600;
  margin-left: 0.5em;
  margin-bottom: 2em;
  text-decoration: underline;
  color: rgb(255, 255, 255);
  text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
}

.event-display-dots-wrap {
  position: absolute;
  width: 1em;
  margin-top: -0.8em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.event-display-dots-wrap-hidden-events-count {
  background: #fff;
  border: 1px solid #0003;
  bottom: -2.3em;
  white-space: pre;
  font-size: 0.4em;
  position: absolute;
  z-index: 100;
}
.default-event-dot-outer {
  position: relative;
  border-radius: 50%;
  border: 1px solid;
}
.default-event-dot {
  position: relative;
  border-radius: 50%;
  border: 2px solid;
}

.event-display-dots-event-single {
  margin-top: 0.3em;
}

.event-year-search-form {
  padding: 0 1em;
}

.season-class-1 {
  background: linear-gradient(
    90deg,
    #c1f7ff 0%,
    #cbf3f9 5%,
    #e0f8fc 40% 50%,
    #cbf3f9 70%,
    #c1ffe2 100%
  );
}
.season-class-2 {
  background: linear-gradient(
    90deg,
    #c1ffe2 0%,
    #a5ffa1 5%,
    #12e477 40% 50%,
    #a5ffa1 70%,
    #cdff60 100%
  );
}
.season-class-3 {
  background: linear-gradient(
    90deg,
    #cdff60 0%,
    #ffea60 5%,
    #f08222 40% 50%,
    #ffea60 70%,
    #d6ccaf 100%
  );
}
.season-class-4 {
  background: linear-gradient(
    90deg,
    #d6ccaf 0%,
    #d6beaf 5%,
    #deaa8a 40% 50%,
    #d6beaf 70%,
    #c1f7ff 100%
  );
}

.spinner-centerer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 0;
}
.spinner-centerer-inner {
  position: relative;
  top: 3em;
}

.year-label-year-view {
  font-size: 0.8em;
  transform: rotate(-70deg);
  position: relative;
  top: -2.4em;
  left: -0.35em;
  background: white;
  width: fit-content;
  padding: 0 0.2em;
  border-radius: 1em;
  min-width: 2.5em;
}

.year-label-month-view {
  position: relative;
  top: 0.8em;
  padding: 1em 0.3em;
  padding-top: 3em;
}

.center-label-year-view {
  position: relative;
  top: 3em;
  left: -0.5em;
}

.event-dot-column-corrector-day {
  width: 100%;
  position: relative;
  top: 1em;
}
.event-dot-column-corrector-month {
  width: 100%;
  position: relative;
  top: 0.8em;
}
.event-dot-column-corrector-year {
  width: 100%;
  position: relative;
  top: 1em;
}

.fade-in-item {
  animation: fadein 1s ease-out;
}

.event-year-map-controls-wrap {
  position: relative;
}

.event-year-map-controls-image {
  width: 100%;
  position: absolute;
  height: 100%;
  background-position: left;
  background-size: cover;
}

.event-year-map-controls {
  background: rgb(242 242 242 / 95%);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.2em;
  display: flex;
  align-items: center;
  padding: 0.5em 0.5em;
  padding-bottom: 0;
  overflow-x: auto;
  justify-content: space-between;
  position: relative;
  z-index: 1;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.skip-days-popup {
  padding: 1em;
  position: relative;
  background: white;
  font-size: 4em;
  i {
    font-size: 0.6em;
    margin-right: 1em;
  }
}

.skip-days-popup-outer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.1);
}

.skip-days-popup-alt {
  position: absolute;
  top: 0;
  right: 0.1em;
  margin-top: -0.5em;
  font-size: 1em;
}

.invalid-leap-day {
  opacity: 0.3;
  background: linear-gradient(0, #a7a7a7, #f1f1f1);
}

.controls-buttons-on-right {
  position: relative;
  top: 0.4em;
  display: flex;
  gap: 1em;
}

.left-lower-events-toolbar {
  min-width: 15em;
  display: flex;
  align-items: center;
  gap: 1em;
}
