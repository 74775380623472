.better-slider-label-wrap {
  display: flex;
  align-items: center;
  padding-left: 0.6em;
  position: absolute;
  width: 100%;
}

.better-slider-label {
  font-size: 0.8em;
  white-space: nowrap;
  padding: 0.2em 0.65em;
  color: #414040de;
  text-align: center;
}

.better-slider-label-negative {
  background: linear-gradient(90deg, red, #fffb00);
}

.better-slider-label-neutral {
  background: linear-gradient(90deg, #fffb00, #cdff00);
}

.better-slider-label-positive {
  background: linear-gradient(90deg, #cdff00, #09ff00);
}

.better-slider-wrap {
  position: relative;
}

.better-slider-input-wrap {
  position: relative;
  width: 100%;
  top: 0.5em;
}
