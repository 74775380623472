.book-list-picker-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.book-list-picker-row-outer {
  font-size: 0.9em;
  border: 1px solid #ccc;
  margin-bottom: 0.5em;
  background-color: rgba(255, 255, 255, 0.651);
}

.book-list-picker-slot {
  img {
    height: 6em;
  }
  padding: 0.5em;
  .ms-Label {
    font-size: 1em !important;
  }
  font-size: 1em;
}

.book-list-result-wrap {
  overflow-y: auto;
  max-height: 50vh;
}
