.home-page-wrap {
}

.home-page-huge-title {
  font-size: 4em;
  text-align: center;
  padding: 1em;
}

.home-page-min-title {
  font-size: 2em;
  text-align: center;
  padding: 1em;
  color: rgba(0, 0, 0, 0.4);
  width: 70vw;
  margin: 0 auto;
  margin-top: -2em;
  margin-bottom: 1em;
}

.home-page-banner-line {
  background-color: rgb(97 151 214);
  padding: 1em;
}
.home-page-center-big-button {
  display: flex;
  align-items: center;
  justify-content: space-around;
  button {
    margin-top: 1em;
    padding: 1em 2em;
    min-width: 15em;
  }
}

.home-page-text-big {
  font-size: 2em;
  color: white !important;
}
.home-page-text-normal {
  font-size: 1.5em;
  margin: 1em 0;
  color: white !important;
  strong {
    color: white !important;
  }
}

.home-page-tile {
}

.home-page-tile-wrap {
  overflow: hidden;
  position: relative;
  display: inline-grid;
  width: 24vw;
  border-radius: 1em;
  font-size: 1.3em;
  box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin: 1em;
}

.home-page-tile-wrap-mobile {
  position: relative;
  border-radius: 1em;
  background: rgba(0, 0, 0, 0.05);
  font-size: 1.3em;
  box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin: 1em;
}

.home-page-tile-row {
  display: flex;
  align-items: start;
  justify-content: space-around;
}

.home-page-tile-title {
  font-size: 2em;
  padding: 0.4em;
}

.home-page-tile-description {
  font-size: 1.2em;
  padding: 0.4em;
}

.home-page-tile-icon {
  text-align: center;
  img {
    box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
    width: 10em;
    height: 10em;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
}

.home-page-tile-background {
  position: absolute;
  opacity: 0.05;
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: 1em;
  img {
    height: 100%;
    width: 100%;
  }
}
