.char-meta-form-row {
  display: flex;
  align-items: end;
  gap: 1em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 1em;
  width: 80vw;
  max-width: 800px;
}
.char-meta-form-row-mobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 1em;
}

.char-meta-button-on-right {
  display: flex;
  flex-direction: row-reverse;
  padding: 1em 0.2em;
}

.meta-selector-wrap {
  min-height: 10vh;
  min-width: 400px;
}

.meta-selector-wrap-mobile {
  min-height: 10vh;
}

.slider-input-row {
  display: flex;
  align-items: center;
}
.slider-input-slider {
  width: 100%;
}

.metadata-generic-info-container {
  display: flex;
  gap: 1em;
}

.metadata-generic-info-container-spinner {
  padding: 3em;
}
