.chat-page-main-wrap {
  display: flex;
  align-items: center;
  height: calc(100vh - 60px);
  width: 100vw;
  overflow-y: hidden;
}

.chat-page-left-menu {
  width: 250px;
  background-color: rgb(223, 224, 234);
  height: 100%;
  max-width: 35vw;
  box-shadow: 0 0 1em 0 #aeaeae;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.chat-page-right-menu {
  height: 100%;
}
.top-of-chat-messages {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 1em;
}
.book-chat-line {
  padding: 0.2em 1em;
  cursor: pointer;
  position: relative;
}
.book-chat-line:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.book-chat-line-active {
  background-color: rgba(0, 0, 0, 0.2) !important;
  font-weight: 600;
}

.chat-page-left-menu-title {
  display: flex;
  align-items: center;
  gap: 1em;
  margin: 1em;
  padding-bottom: 0.3em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  span {
    font-weight: 600;
  }
}
.chat-page-left-menu-title-mobile {
  flex-direction: column;
  align-items: baseline;
}
.book-chat-line-counter {
  background: #e93a3a;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 30%;
  padding: 3px;
  font-size: 8px;
  color: white;
  position: absolute;
  right: -3em;
  top: 0;
  min-width: 1.5em;
  text-align: center;
}

.chat-page-left-menu-wrap {
  overflow-y: auto;
  height: 85vh;
}

.chat-page-chat-message {
  padding: 0.2em;
  background: #e3eeff;
  border-radius: 1em;
  margin: 1em;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 80%;
}
.chat-page-chat-message-your {
  padding: 0.2em;
  margin-left: 1em;
  background-color: #f1f8f4;
}
.chat-page-chat-message-title {
  padding: 0.2em;
  font-size: 0.8em;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6em;
}
.chat-page-chat-message-file-link {
  font-size: 0.8em;
}
.chat-page-chat-message-file-link-row {
  display: flex;
  gap: 1em;
  align-items: center;
  img {
    height: 1em;
  }
}
.chat-page-chat-message-file-link-wrap {
  padding: 0 0.2em;
}
.chat-page-chat-message-content {
  padding: 0.2em;
}
.chat-messages-main-wrap {
  overflow-y: auto;
  height: 84vh;
}

.chat-bar-commands {
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  gap: 1em;
  padding: 0.4em;
  background-color: #cbdcf1;
  width: 100%;
  padding-left: 1em;

  textarea {
    resize: none;
    width: 75%;
  }
}

.command-bar-file-slot-item {
  background-color: #f3f3f6;
  border: 1px solid silver;
  width: -moz-fit-content;
  width: fit-content;
  display: inline-block;
  font-size: 0.8em;
  margin: 0.3em;
  padding: 0.2em 1em;
  border-radius: 1em;
  color: #a6a3be;
}
.command-bar-file-slot {
  position: absolute;
  min-height: 3em;
  margin-left: -1em;
  width: 100%;
  padding: 0.3em 1em;
  padding-bottom: 1em;
  background: #cbdcf1;
}
.command-bar-file-slot-item-inner {
  display: flex;
  gap: 1em;
  align-items: center;
}
