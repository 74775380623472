.work-group-info-card-main-wrap {
  img {
    height: 6em;
    border-radius: 1em;
    box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.1);
  }
  button {
    margin-top: 0.4em;
  }
}

.work-group-info-main-row {
  display: flex;
  align-items: center;
  gap: 1em;
}
.role-label {
  font-size: 0.8em;
  border: 1px solid #f3f3f3;
  padding: 0.2em 1em;
  margin-top: 1em;
  span {
    font-size: 1.1em;
    font-weight: 600;
    font-style: italic;
  }
}
