.coach-mark-main-positioner {
  position: absolute;
  animation: fadein 1s ease-out;
  z-index: 10000;
}

.coach-mark-marker {
}

.coach-mark-mild-popup {
  background: rgb(36 131 246);
  position: relative;
  z-index: 10;
  max-height: 0;
  max-width: 0;
  overflow: hidden;
}
.coach-mark-mild-popup-open {
  animation: rollIn 0.2s ease-out;
  max-width: max(30vw, 200px);
  max-height: unset;
  min-width: 200px;
  overflow: auto;
  padding: 1em;
  box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
}

.coach-mark-mild-popup-content {
  max-height: 40vh;
  overflow: auto;
  color: white;
  div,
  span {
    color: white;
  }
  margin-bottom: 1em;
}

.fader-element {
  transition: opacity 0.4s ease-out;
}

.coach-mark-mild-popup-title {
  div,
  button {
    color: white;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.2em;
  font-weight: 600;
  margin-bottom: 0.5em;
}

.coach-mark-marker-bubble {
  width: 25px;
  height: 25px;
  background: radial-gradient(white, rgb(36 131 246));
  border-radius: 50%;
  animation: pulse 1s linear infinite;
  position: absolute;
}

.coach-mark-pointer-top {
  width: 0;
  height: 0;
  position: fixed;
  margin-top: -8px;
  margin-left: 1.5px;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-bottom: 20px solid rgb(36 131 246);
}

.coach-mark-pointer-bottom {
  width: 0;
  height: 0;
  position: absolute;
  margin-top: 14px;
  margin-left: 1.5px;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-top: 20px solid rgb(36 131 246);
}

.coach-mark-pointer-right {
  width: 0;
  height: 0;
  position: absolute;
  margin-top: 1.5px;
  margin-left: 15px;
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  border-left: 20px solid rgb(36 131 246);
}

.coach-mark-pointer-left {
  width: 0;
  height: 0;
  position: absolute;
  margin-top: 1.5px;
  margin-left: -10px;
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  border-right: 20px solid rgb(36 131 246);
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgb(36 131 246);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.coach-mark-mild-popup-buttons {
  display: flex;
  justify-content: space-between;
  gap: 1em;
}

@keyframes rollIn {
  0% {
    transform: translate3d(0px, -10px, 0px);
    pointer-events: none;
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    transform: translate3d(0px, 0px, 0px);
    pointer-events: auto;
    opacity: 1;
  }
}

.coach-mark-mild-popup-positioner-left {
  position: absolute;
  right: -1.5em;
}

.mobile-popup-positioner {
  right: 0;
  left: 0;
  width: 90%;
  max-width: unset;
  position: fixed;
}
