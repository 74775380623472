.answer-wrap {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.5em;
  padding: 1em;
  background: linear-gradient(45deg, #d2deec, transparent);
  margin: 0.5em;
}
.answer-title {
  font-weight: 600;
  margin-bottom: 0.2em;
}
.answer-content {
  font-size: 1.2em;
  background: rgba(255, 255, 255, 0.5);
  padding: 0.3em;
}

.answer-main-box {
  max-height: 45vh;
  overflow: auto;
}

.answer-elapsed {
  float: right;
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.5);
  white-space: pre;
}

.is-date-div {
  display: inline-block;
  color: #a45c3b;
}

.is-character-div {
  display: inline-block;
  color: #7979be;
  font-weight: 600;
}
.is-object-div {
  display: inline-block;
  color: #dc66f1;
  font-weight: 600;
}
.is-other-characters-div {
  display: inline-block;
  color: #358540;
  font-weight: 600;
}
.is-date-timeline-div {
  display: inline-block;
  color: #00b2eb;
}

.response-bot-line {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
