.accordion {
  margin: 1em 0;
  width: 90%;
}
.accordion-header {
  background-color: rgba(0, 0, 0, 0.075);
  padding: 0 1em;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.accordion-header-left {
  display: flex;
  align-items: center;
  gap: 1em;
}
.accordion-content {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.63);
  padding: 1em;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top: none;
}
