.table-pretty-main-wrap {
  width: 100%;
  border-spacing: 0 0;
  background: white;
  border-right: 1px solid rgba(0, 0, 0, 0.2);

  th {
    z-index: 1000;
    text-align: left;
    padding: 0.5em 1em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    position: sticky;
    top: 0px; /* 0px if you don't have a navbar, but something is required */
    background: rgb(234 243 255);
  }
  td {
    text-align: left;
    padding: 0.5em 1em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    border-left: 1px solid rgba(0, 0, 0, 0.2);
  }
  .odd-table-row {
    background-color: rgba(0, 0, 0, 0.035);
  }
}

.pretty-table-pager-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.4em 0.5em;
  overflow-x: auto;
  gap: 0.5em;
}

.table-pretty-main-wrap-outer {
  width: 100%;
  overflow: auto;
}
