.login-page-wrap {
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100vw;
  height: calc(100vh - 60px);
}

.login-inner-frame {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
