.confirm-email-page-wrap {
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100vw;
  height: calc(100vh - 61px);
}

.confirm-email-inner-frame {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.confirmation-done-subtitle {
  text-align: center;
  margin-top: 2em;
}
.confirmation-done-icon {
  display: flex;
  flex-direction: column;
  gap: 1em;
  color: green;
  font-size: 3em;
  text-align: center;
  i {
    color: green;
  }
}
.confirmation-not-done-icon {
  display: flex;
  flex-direction: column;
  gap: 1em;
  color: red;
  font-size: 3em;
  text-align: center;
  i {
    color: red;
  }
}
