.main-footer-wrap {
  background: color(srgb 0.2332 0.3702 0.53);
  padding: 2em;
  padding-bottom: 5em;
}

.footer-column {
  display: inline-grid;
  min-width: 20em;
  padding-left: 1em;
  margin-top: 1em;
}
.footer-title {
  font-size: 2em;
  color: white;
}
.footer-label > * {
  font-size: 1.2em;
  margin-top: 0.5em;
  color: white !important;
}
