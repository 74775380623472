.gallery-main-wrap {
  padding: 0;
  max-width: 80vw;
}

.gallery-item {
  padding: 2px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: white;
}

.gallery-title-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5em 0;
}
