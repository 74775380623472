.not-found-title {
  font-size: 3em;
  text-align: center;
  padding: 1em;
}

.not-found-number {
  font-size: 8em;
  text-align: center;
}

.not-found-sub-title {
  font-size: 1.2em;
  text-align: center;
  padding: 1em;
}
.not-found-go-to-login {
  text-align: center;
  padding: 0.2em;
}
