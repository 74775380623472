.language-picker-image {
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 2em;
  width: 2em;
  position: relative;
}

.language-picker-select {
  width: 2.5em;
  height: 2.2em;
  position: absolute;
  z-index: 10;
  opacity: 0;
  left: 0;
}

.language-picker-wrap {
  position: relative;
  margin-left: 1em;
  height: 2em;
}
