.book-explorer-resume {
  padding: 1em;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: rgba(0, 0, 0, 0.05);
  overflow-x: auto;
}

.book-explorer-resume-item {
  display: inline-block;
  margin-right: 1em;
}
.book-explorer-resume-commands {
  display: flex;
  align-items: baseline;
  gap: 0.5em;
}

.book-explorer-resume-commands-wide {
  display: flex;
  justify-content: space-between;
  gap: 1em;
}
.book-explorer-resume-commands-wide-mobile {
  flex-direction: column;
}

.timeline-dropdown-selector {
  position: relative;
  display: flex;
  gap: 1em;
  border-right: 1px solid;
  border-left: 1px solid;
  padding: 0 1em;
  border-color: #54545459;
}

.timeline-dropdown-selector-select {
  min-width: 15em;
}
.create-timeline-button {
  padding: 2em;
  align-content: center;
  justify-content: space-around;
  display: flex;
}

.start-work-label {
  text-align: center;
  margin-top: 5em;
  font-size: 1.5em;
}
