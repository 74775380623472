.event-page-main-wrap {
  display: flex;
  height: calc(100vh - 60px);
  width: 100vw;
}
.left-menu {
  width: 13em;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.1);
  padding: 0.5em;
  transition: margin-left 0.3s ease-out;
  background: white;
}

.mobile-left-menu {
  position: absolute;
  height: 100vh;
  margin-left: -19em;
  z-index: 1000001;
}

.left-menu-inkdrop {
  position: fixed;
  left: 0;
  top: 60px;
  height: 100vh;
  width: 0;
  background: rgba(0, 0, 0, 0.2);
  transition: width 0.2s ease-out;
  z-index: 1000000;
}
.hidden-item {
  display: none;
}
.chat-red-dot-signal {
  padding: 0.1em 0.5em;
  position: absolute;
  background: #e93a3a;
  border-radius: 1em;
  right: -0.3em;
  top: -0.3em;
  z-index: 10;
  text-align: center;
  color: white;
}
.left-menu-inkdrop-open {
  width: 100vw;
}

.mobile-left-menu-open {
  margin-left: 0;
}

.right-page {
  width: calc(100% - 13em);
  overflow-x: hidden;
}

.right-page-mobile {
  width: 100vw;
}

.event-book-title {
  font-size: 1.2em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0.2em;
  cursor: pointer;
}
.event-book-title:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.book-library-main-column {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  background-color: rgb(249, 249, 249);
  padding: 0.5em;
  min-height: 45vh;
}

.start-work-label {
  text-align: center;
  margin-top: 5em;
  font-size: 1.5em;
}

.menu-button-wrap {
  button {
    border-color: rgba(0, 0, 0, 0.2);
  }
}
