.color-picker-input {
  max-width: 3em;
}

.color-picker-input-wrap {
}

.color-picker-internal {
  display: flex;
  align-items: center;
  gap: 1em;
}
