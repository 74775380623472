.better-combo-wrap {
  position: relative;
}
.better-combo-options {
  position: fixed;
  background: white;
  box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
  max-height: 15em;
  overflow-y: auto;
  animation: rollIn 0.2s ease-out;
  z-index: 2;
}
.better-combo-option {
  padding: 0.8em 1em;
  color: rgb(90, 90, 89);
}
.better-combo-option:hover {
  cursor: pointer;
}
.better-combo-option-active {
  background: rgb(225, 223, 221);
}

@keyframes rollIn {
  0% {
    transform: translate3d(0px, -10px, 0px);
    pointer-events: none;
    opacity: 0;
  }
  100% {
    transform: translate3d(0px, 0px, 0px);
    pointer-events: auto;
    opacity: 1;
  }
}

.better-combo-selected-voice {
  background: white;
  padding: 0.6px 0.2em;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgb(163, 162, 160);
}
.better-combo-cancel-positioner {
  position: absolute;
  margin-top: -1.05em;
  margin-left: -2em;
  width: 2.1em;
  height: 2.1em;
  overflow: hidden;
}

.disabled-combo-label {
  background: #f3f2f1;
  border: none;
}
