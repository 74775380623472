.animation-text-container {
  background: rgb(255 255 255 / 9%);
  text-align: center;
  position: absolute;
  width: 100vw;
  padding: 1em 0;
  img {
    width: 2em;
    height: 2em;
    border-radius: 0.5em;
    background: #dee6ff;
    border: 1px solid #2e4068;
    box-shadow: 0 0 1em 0 rgb(255 255 255 / 54%);
  }
}

.animation-text-undertex-label {
  color: white;
}

.animation-text-undertex {
  font-size: 1.5em;

  display: flex;
  align-items: center;
  gap: 1em;
}

.animation-text-undertex-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
}

// CUSTOMIZE BELOW

.patterns {
  width: 100vw;
  margin-bottom: -1em;
  position: relative;
  top: -2em;
}

svg text {
  stroke: rgb(255, 255, 255);
  font-size: 3em;
  font-weight: 700;
  stroke-width: 1;

  animation: textAnimate 4s infinite alternate;
}

@keyframes textAnimate {
  0% {
    stroke-dasharray: 0 50%;
    stroke-dashoffset: 20%;
    fill: hsl(189, 68%, 75%);
  }
  30% {
    stroke: rgb(255, 255, 255);
  }
  50% {
    stroke: rgb(189, 245, 255);
  }
  60% {
    stroke: rgb(53, 217, 246);
  }
  70% {
    stroke: rgb(255, 255, 255);
  }
  100% {
    stroke: rgb(255, 255, 255);
    stroke-dasharray: 50% 0;
    stroke-dashoffstet: -20%;
    fill: hsla(189, 68%, 75%, 0%);
  }
}
