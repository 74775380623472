.toolbar-main-wrap {
  position: relative;
  display: flex;
  gap: 1em;
  align-items: center;
  justify-content: space-between;
  padding: 0.2em 1em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 10;
  height: 55px;
  .ms-Persona {
    display: flex;
    flex-direction: row-reverse;
  }

  .ms-Persona-details {
    text-align: right;
  }
}

.toolbar-logo {
  height: 3em;
  position: relative;
  top: 0.2em;
}

.persona-container {
  padding: 0.2em 1em;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #e7f2ff;
  border-radius: 1em;
  .ms-Persona-initials {
    span {
      color: white;
    }
  }
}

.toolbar-offline-message {
  position: absolute;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 1000;
  .ms-MessageBar-content {
    align-items: center;
  }
}

.user-menu-button {
  button {
    width: 14em;
  }
  margin: 0.5em;
}

.right-content-toolbar-wrap {
  display: flex;
  gap: 1em;
  align-items: center;
}

.toolbar-logo-row {
  display: flex;
  gap: 1em;
  align-items: center;
  cursor: pointer;
}

.toolbar-logo-label {
  font-weight: 600;
}
