.subscription-recap-wrap {
  padding-top: 1em;
  font-size: 0.8em;
}

.subscription-name {
  font-size: 2em;
  font-weight: 600;
}

.subscription-description {
  font-size: 1.1em;
  color: rgba(0, 0, 0, 0.6);
}
.subscription-description-mobile {
  height: unset;
  padding-bottom: 1em;
}

.subscription-type {
  text-align: center;
  font-size: 2em;
}

.subscription-image {
  text-align: center;
  padding-top: 0.5em;
  img {
    object-fit: cover;
    box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
    border-radius: 1em;
    width: 10em;
    height: 10em;
  }
}

.subscription-price {
  text-align: center;
  font-size: 4em;
  white-space: pre;
}
.subscription-price-yearly {
  margin-top: 1em;
  text-align: center;
  font-size: 1.2em;
  white-space: pre;
}
.subscription-workgroup-size {
  font-size: 1.2em;
}

.subscription-workgroup-supported {
  font-size: 1.2em;
}

.subscription-main-wrap {
  display: flex;
  align-items: center;
  padding: 1em;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.2em;
  margin: 0.5em;
  box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.1);
}
.subscription-main-wrap-mobile {
  flex-direction: column;
  .subscription-name-desc-wrap {
    padding: 1em;
    width: 100%;
  }
  .subscription-button-section {
    flex-direction: column;
  }
}

.subscription-main-wrap-outer {
  overflow-x: hidden;
}
.subscription-includes-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2em;
  width: 80%;
  font-size: 1.2em;
  :nth-child(1) {
    width: 80%;
  }
  padding: 0.5em 1em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.subscription-includes {
  font-size: 1.5em;
  margin-left: 0.8em;
  font-weight: 600;
  margin-top: 0.4em;
}

.subscription-name-desc-wrap {
  width: 40%;
  padding-right: 2em;
}

.subscription-type-price-wrap {
  margin: 0 2em;
}

.subscription-button-section {
  display: flex;
  align-items: center;
  gap: 2em;
}

.subscription-free-trial-hint {
  width: 80%;
  margin-top: 0.5em;
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.5);
}

.subscription-center-spinner {
  display: flex;
  align-items: end;
  justify-content: space-around;
  height: 10em;
}

.subscription-recap-title {
  font-size: 2em;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 1em;
}

.subscription-recap-description {
  margin-top: 0.5em;
  padding: 0.5em 0;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 1.2em;
}

.subscription-recap-payment-recurrence {
  font-size: 1.2em;
  margin: 0.5em 0;
  margin-top: 0;
  padding: 0.5em 0;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.subscription-recap-includes-zone {
  margin-top: 1em;
  .subscription-includes-line {
    width: 100%;
  }
}

.subscription-recap-payment-price {
  font-size: 2.5em;
  padding: 0;
  text-align: right;
}

.subscription-coupon-commands {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;
}

.paypal-payment-wrap {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.coupon-line-wrap {
  display: flex;
  flex-direction: row-reverse;
  gap: 1em;
}

.nopay-banner {
  color: #6c746c;
  text-align: center;
  padding: 1em;
  font-size: 2em;
  background: #e7fee7;
}

.center-spinner-store {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 10em;
}

.gobutton-payment {
  padding: 2em;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.purchase-button-mobile {
  display: flex;
  flex-direction: column;
  gap: 1em;
  button {
    width: 100% !important;
  }
}

.subscription-free-trial-large {
  position: relative;
  background: linear-gradient(45deg, #5d718a, #71a7e9);
  padding: 0.5em;
  font-size: 1.5em;
  color: white;
  margin-top: 1em;
}

.subscription-includes-title {
  font-weight: 600;
}

.subscription-includes-description {
  font-size: 0.9em;
  opacity: 0.8;
}

.subscription-includes-amount {
  font-size: 1.2em;
  font-weight: 600;
}

.subscription-includes-amounts {
  min-width: min(10em, 10vw);
}

.subscription-includes-amounts-label {
  white-space: pre;
}

.subscription-list-box {
  min-width: 50%;
}

@media screen and (max-width: 1200px) {
  .subscription-main-wrap {
    flex-direction: column;
    .subscription-name-desc-wrap {
      padding: 1em;
      width: 100%;
    }
    .subscription-button-section {
      flex-direction: column;
    }
  }
}
