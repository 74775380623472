.wgmembers-row {
  display: flex;
  align-items: center;
  gap: 1em;
  padding: 0.4em 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.wgmembers-row-uname {
  font-weight: 600;
  min-width: 12em;
}

.wgmembers-row-email {
  color: rgb(0 0 0 / 68%);
  min-width: 16em;
  font-size: 0.8em;
}

.wgmembers-row-type {
  font-weight: 600;
  min-width: 10em;
  text-transform: uppercase;
}
.wgmembers-button-right-zone {
  margin: 1em 0;
  margin-top: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 1em;
}

.wgmembers-userlist-mainwrap {
  max-height: 70vh;
  overflow: auto;
}

.wgmembers-button-right-message {
  padding-top: 1em;
  max-width: 40em;
}

.recap-permission-box {
  background-color: rgb(127 127 127 / 20%);
  padding: 0.2em 1em;
  width: fit-content;
  border-radius: 5px;
  margin: 0.2em;
  border: 1px solid #f7f7f7;
  display: inline-block;
}

.edit-team-member-wrap {
  padding: 1em 0;
}

.edit-team-member-top-line {
  display: flex;
  align-items: center;
  gap: 1em;
  justify-content: space-between;
  padding-bottom: 0.2em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 0.2em;
}
