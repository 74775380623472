html * {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  div {
    color: rgb(85 89 120);
  }
}

body {
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.flex-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2em;
}

.page-under-toolbar-wrap {
  overflow-y: auto;
  max-height: calc(100vh - 60px);
}

.end-padding {
  height: 5em;
}

.loading-main-image {
  height: 100vh;
  width: 100vw;
  background: black;
  img {
    height: 35vh;
  }
}

.will-fadein {
  animation: fadein 1s ease-out;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.update-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  background: radial-gradient(#e5eff1 50%, #93c4ff);
  height: 100vh;
}

.update-container-label {
  max-width: 20em;
  padding: 1em;
  font-size: 1.2em;
  font-weight: 600;
  text-align: center;
}
.ms-Dropdown-item {
  height: unset;
}

.ms-Callout-main {
  min-width: fit-content;
}

.loading-main-spinner {
  padding: 0;
  margin-top: 20vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.admin-messages-container {
  width: 100vw;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  overflow: hidden;
}
.is-event-dropdown {
  .ms-Dropdown-item {
    height: unset;
    display: inline-block;
    width: fit-content;
    background-color: #efeeff;
    transition: background-color 0.3s ease-out;
    margin: 0.5em;
  }
  .ms-Dropdown-item:hover {
    background-color: #a2a0bc !important;
  }
}

.intro-image-wrapper-spark {
  width: 100%;
  text-align: center;
  background-color: black;
}

.intro-box-centerer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.under-input-hint {
  font-size: 0.8em;
  opacity: 0.8;
}
