.contact-us-page-wrap {
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100vw;
  height: calc(100vh - 61px);
}

.contact-us-done-icon {
  display: flex;
  flex-direction: column;
  gap: 1em;
  color: green;
  font-size: 3em;
  text-align: center;
}
